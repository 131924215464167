import React, { useState } from "react";
import { Typography, Grid, Paper, Box, IconButton } from "@mui/material";
import { ClearSharp as DeleteIcon } from "@mui/icons-material";
import RatingsBar from "./RatingsBar";
import HoursBar from "./HoursBar";
import InfoBar from "./InfoBar";
import RestaurantPhoto from "./RestaurantPhoto";
import MoreHoursModal from "./MoreHoursModal";

const RestaurantCard = ({ restaurantDetail, handleDeleteRestaurant }) => {
  const [isHourModalOpen, setisHourModalOpen] = useState(false);
  const handleOpenHourModal = () => {
    setisHourModalOpen(true);
  };
  const handleCloseHourModal = () => {
    setisHourModalOpen(false);
  };

  return (
    <Paper
      sx={{
        p: 2,
        width: 600,
        maxWidth: 600,
      }}
    >
      <Grid container spacing={2}>
        <Grid item sx={{ width: 148, height: 128 }}>
          {/* Restaurant Photo */}
          <RestaurantPhoto photos={restaurantDetail.photos} />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            {/* Restaurant Nmae */}
            <Grid item xs>
              <Typography variant="subtitle1" fontWeight="bold" component="div">
                {restaurantDetail.name}
              </Typography>
              {/* Rating bar */}
              <RatingsBar
                stars={restaurantDetail.rating}
                numOfReview={restaurantDetail.user_ratings_total}
                priceLevel={restaurantDetail.price_level}
              />
              {/* Hours */}
              <HoursBar
                currentHours={restaurantDetail.current_opening_hours}
                handleOpenHourModal={() => handleOpenHourModal()}
              />
              <Box height="5px" />
              {/* Address */}
              <InfoBar
                label="Address:"
                content={restaurantDetail.formatted_address.split(",").slice(0, -1)}
              />
              {/* Phone */}
              <InfoBar label="Phone:" content={restaurantDetail.formatted_phone_number} />
              {/* Website */}
              {restaurantDetail.website && (
                <InfoBar
                  label="Website:"
                  content={restaurantDetail.website}
                  url={restaurantDetail.website}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {/* Delete button */}
          <IconButton onClick={handleDeleteRestaurant}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <MoreHoursModal
        openHours={restaurantDetail.current_opening_hours.weekday_text}
        isOpen={isHourModalOpen}
        handleClose={() => handleCloseHourModal()}
      />
    </Paper>
  );
};

export default RestaurantCard;
