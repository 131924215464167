import React from "react";
import MainSection from "./MainSection.js";
import AboutSection from "./AboutSection.js";
import SelectionsSection from "./ScenariosSection.js";
import Footer from "./Footer.js";

const HomePage = () => {
  return (
    <div>
      <MainSection></MainSection>
      <SelectionsSection></SelectionsSection>
      <AboutSection></AboutSection>
      <Footer></Footer>
    </div>
  );
};

export default HomePage;
