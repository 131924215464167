import { styled } from "@mui/material/styles";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const NO_IMAGE_AVAILABLE =
  "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg";

const RestaurantPhoto = ({ photos }) => {
  return (
    <Img
      alt="complex"
      src={photos && photos.length > 0 ? photos[0].getUrl() : NO_IMAGE_AVAILABLE}
    />
  );
};

export default RestaurantPhoto;
