import React, { useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const SpeechRecognitionComponent = ({ setPreferenceText }) => {
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    setPreferenceText(transcript);
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-US" });
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
  };

  const handleResetTranscript = () => {
    resetTranscript();
  };

  return (
    <div>
      <Tooltip title="Voice Input">
        <span>
          <IconButton color="primary" onClick={handleStartListening} disabled={listening}>
            <MicIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Stop">
        <span>
          <IconButton sx={{ color: "red" }} onClick={handleStopListening} disabled={!listening}>
            <StopIcon />
          </IconButton>{" "}
        </span>
      </Tooltip>
      <Tooltip title="Reset">
        <IconButton color="primary" onClick={handleResetTranscript}>
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default SpeechRecognitionComponent;
