import React from "react";
import { Typography, Grid, List, Modal, Card, Paper } from "@mui/material";
import { modalStyle } from "../../../styles/modalStyles";
import { colors } from "../../../styles/_defaults";

const hoursCardStyle = {
  borderRadius: "20px",
  boxShadow: "0px 4px 10px rgba(0.1, 0.1, 0.1, 0.1)",
  padding: "24px",
  minHeight: "350px",
  minWidth: "280px",
};

const columnStyle = {
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  boxShadow: "none",
  marginBottom: "5px",
  borderTop: "1px solid #ccc",
};

const dayFontStyle = {
  marginTop: "5px",
  fontSize: "14px",
  fontWeight: "bold",
  color: colors.primary,
};

const timeFontStyle = {
  fontSize: "14px",
};

const MoreHoursModal = ({ openHours, isOpen, handleClose }) => {
  return (
    <Modal open={isOpen} onClose={handleClose} style={modalStyle}>
      <Card style={hoursCardStyle}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Hours
          </Typography>
        </Grid>
        <Grid item>
          <List>
            {openHours.map((day_text, index) => (
              <Paper key={index} style={columnStyle}>
                <Typography style={dayFontStyle}>{day_text.split(": ")[0]}</Typography>
                <Typography style={timeFontStyle}>{day_text.split(": ")[1]}</Typography>
              </Paper>
            ))}
          </List>
        </Grid>
      </Card>
    </Modal>
  );
};

export default MoreHoursModal;
