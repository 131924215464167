import React from "react";
import { Grid } from "@mui/material";
import UserPreferenceTextBox from "./UserPreferenceTextBox";
import SpeechRecognitionComponent from "../buttons/SpeechRecognitionComponent";

export default function UserPreference({ preferenceText, setPreferenceText }) {
  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={11}>
        <UserPreferenceTextBox
          preferenceText={preferenceText}
          setPreferenceText={setPreferenceText}
        />
      </Grid>
      <Grid item xs={1}>
        <SpeechRecognitionComponent setPreferenceText={setPreferenceText} />
      </Grid>
    </Grid>
  );
}
