import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import usePlacesAutocomplete from "use-places-autocomplete";

const PlacesAutocompleteSearchBarBase = ({ setSelected }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    if (address) {
      setValue(address, false);
      clearSuggestions();
      setSelected(address);
    }
  };

  return (
    <Autocomplete
      style={{ marginTop: "12px" }}
      freeSolo
      inputValue={value}
      onInputChange={(_, newInputValue) => setValue(newInputValue)}
      options={status === "OK" ? data.map((item) => item.description) : []}
      loading={!ready}
      renderInput={(params) => (
        <TextField {...params} label="Enter a location" variant="outlined" fullWidth />
      )}
      onChange={(event, newValue) => {
        handleSelect(newValue);
      }}
    />
  );
};

export default PlacesAutocompleteSearchBarBase;
