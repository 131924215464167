import React from "react";
import { Typography, Box, Link } from "@mui/material";
import { colors } from "../../../styles/_defaults";

const maxWebsiteUrlLen = 40;

// url is optional
const InfoBar = ({ label, content, url }) => {
  return (
    <Box style={{ display: "flex" }}>
      <Typography variant="body2" fontWeight="bold" style={{ marginRight: "4px" }}>
        {label}
      </Typography>
      {url ? (
//If url is provided
        <Typography variant="body2">
          <Link // have cursor pointer by defualt
            href={url}
            target="_blank" // open in new tab
            rel="noopener noreferrer" // security purpose
            style={{
              color: colors.primary,
              textDecoration: "none",
            }} // remove underline
          >
            {url.length > maxWebsiteUrlLen ? (
              <>
                {url.substring(0, maxWebsiteUrlLen)}
                <span>....</span>
              </>
            ) : (
              url
            )}
          </Link>
        </Typography>
      ) : (
// If no url provided 
        <Typography variant="body2">{content}</Typography>
      )}
    </Box>
  );
};

export default InfoBar;
