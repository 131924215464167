import React from "react";
import { Container, Typography, Grid, IconButton, Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Container maxWidth={false} style={{ backgroundColor: "white" }}>
      <Grid container sx={{ pt: 20 }}>
        <Grid item xs={12}>
          <Stack direction="row" sx={{ mx: "5%" }}>
            <Typography variant="body2" sx={{ my: "auto" }}>
              {" "}
              &copy; 2023 BiteQuest. All Rights Reserved.
            </Typography>
            <Typography variant="body2" sx={{ ml: "auto", my: "auto" }}>
              Connect With Us
            </Typography>
            <IconButton>
              <FacebookIcon />
            </IconButton>
            <IconButton>
              <TwitterIcon />
            </IconButton>
            <IconButton>
              <LinkedInIcon />
            </IconButton>
            <IconButton>
              <InstagramIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
