import React, { useState } from "react";
import { Typography, Grid, Button, Paper, Snackbar, Alert } from "@mui/material";
import { innerContainerStyle } from "../../styles/containerStyles";
import { getGPTChatCompletion } from "../../services/openaiService";
import { InvalidInputError, ApiError } from "../../errors/customErrors";

const DecisionSection = ({ restaurantDetailList, userPreferences }) => {
  const [decision, setDecision] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleDecide = async () => {
    try {
      // Check if restaurantDetailList and userPreferences are valid
      if (
        !Array.isArray(restaurantDetailList) ||
        restaurantDetailList.length < 1 ||
        !userPreferences
      ) {
        throw new InvalidInputError("Invalid input detected.");
      }
      const messages = buildMessage(userPreferences, restaurantDetailList);
      const response = await getGPTChatCompletion(messages);

      // Extract and set the generated response
      setDecision(response.choices[0].message.content);
    } catch (error) {
      if (error instanceof InvalidInputError) {
        console.error("Invalid input error:", error);
        setErrorMessage("Please add at least 1 restaurant and provide valid user preferences");
      } else {
        console.error("Unexpected error occurred calling openai API:", error);
        setErrorMessage("Unexpected error occurred");
      }
    }
  };

  return (
    <Grid container item xs={12} style={innerContainerStyle}>
      <Grid item xs={12} sx={{ pt: 1 }}>
        <Typography variant="h5">Decision</Typography>
      </Grid>
      <Grid item xs={12} sx={{ pt: 1 }}>
        <Button variant="contained" color="primary" onClick={handleDecide}>
          Decide
        </Button>
      </Grid>

      {decision && (
        <Grid item xs={12} sx={{ pt: 2 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="subtitle1">Generated Decision:</Typography>
            <p>{decision}</p>
          </Paper>
        </Grid>
      )}

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default DecisionSection;

const buildMessage = (userPreferences, restaurantDetailList) => {
  const restaruants = restaurantDetailList.map((restaurantDetail) => {
    return restaurantDetail.name;
  });

  const messages = [
    {
      role: "system",
      content:
        "You are a helpful assisstant who will help me choose a restaurant. You will be given a few sentences on the user's preferences and a list of restaurants. Use your knowledge on these restaurants to pick a suitable option to the best of your abilities",
    },
    {
      role: "user",
      content: `user preferences: ${userPreferences} 
          restaurant options: ${restaruants}`,
    },
  ];

  return messages;
};
