import React from "react";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Typography } from "@mui/material";

export default function LocationInputs({ handleDecide, isDecisionLoading, decision }) {
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
      <LoadingButton
        variant="contained"
        onClick={handleDecide}
        loading={isDecisionLoading}
        sx={{ textAlign: "center" }}
        disabled={isDecisionLoading}
      >
        Recommend A Restaurant !
      </LoadingButton>
      {!isDecisionLoading ? (
        <>
          {decision && (
            <Card
              variant="elevation"
              elevation={3}
              sx={{
                my: 2,
                p: 2,
                borderRadius: "5px",
                minHeight: "100px",
              }}
            >
              <Typography variant="body">{decision}</Typography>
            </Card>
          )}
        </>
      ) : null}
    </Grid>
  );
}
