import React from "react";
import { Typography, TextField } from "@mui/material";
const FREE_TEXT_MAX_CHARACTER_LENGTH = 200;

export default function UserPreferenceTextBox({ preferenceText, setPreferenceText }) {
  return (
    <div>
      <TextField
        id="food-preferences"
        label="Describe your dining preferences"
        multiline
        rows={4}
        variant="outlined"
        value={preferenceText}
        onChange={(e) => setPreferenceText(e.target.value)} // Corrected the onChange handler
        fullWidth
        inputProps={{
          maxLength: FREE_TEXT_MAX_CHARACTER_LENGTH, // Set the maximum number of characters
        }}
      />
      <Typography variant="body2" color="textSecondary" align="right">
        {preferenceText.length}/{FREE_TEXT_MAX_CHARACTER_LENGTH} characters
      </Typography>
    </div>
  );
}
