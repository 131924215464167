export const getRestaurantHourHelper = (periods, isOpen) => {
    if (
      periods.length === 1 &&
      periods[0].open.time === "0000" &&
      periods[0].close.time === "2359"
    ) {
      return isOpen ? "Open 24/7" : "Closed 24/7";
    }
  
    const now = new Date();
    let nextTime = null;
  
    // sort periods, "today" in the front
    const sortedPeriods = [...periods];
    sortedPeriods.sort((a, b) => {
      const dateA = new Date(a.open.date);
      const dateB = new Date(b.open.date);
      return dateA - dateB;
    });
    // console.log("---------sorted periods: ", sortedPeriods);
    for (const period of sortedPeriods) {
      const obj = isOpen ? period.close : period.open;
      if (obj === null) {
        continue;
      }
      const objDate = new Date(obj.date.replace(/-/g, "/")); // convert to yyyy/mm/dd format to avoid js Date one day off 
      const objTime = obj.time.slice(0, 2) + ":" + obj.time.slice(2); // Format time as "HH:mm"
      objDate.setHours(parseInt(objTime.split(":")[0], 10));
      objDate.setMinutes(parseInt(objTime.split(":")[1], 10));
  
      if (objDate >= now) { // find earliest future close/open time
        nextTime = objDate;
        break;
      }
    }
    // convert to display message
    const hours = nextTime.getHours();
    const minutes = nextTime.getMinutes();
    const ampm = hours >= 12 ? "p.m." : "a.m.";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
  
    let statusMessage = isOpen ? "Closes " : "Opens ";
    statusMessage += `${formattedHours}:${formattedMinutes} ${ampm}`;
    
    // if not today, add day Name
    if (now.getDay() !== nextTime.getDay()) {
      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const dayOfTheWeek = dayNames[nextTime.getDay()];
      statusMessage += ` ${dayOfTheWeek}`;
    }
    return statusMessage;
  };