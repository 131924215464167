const API_KEY = process.env.REACT_APP_OPEN_AI_API_KEY;
const API_URL = "https://api.openai.com/v1/chat/completions";

// generate a response from ChatGPT using the new ChatCompletionAPI
// https://platform.openai.com/docs/guides/gpt/chat-completions-api
export const generateResponse = async () => {
  const messages = [
    { role: "system", content: "You are a helpful assistant." },
    {
      role: "user",
      content: "Recommend me a restaurant near University of Toronto.",
    },
  ];

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: messages,
        max_tokens: 100,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGPTChatCompletion = async (messages) => {
  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: messages,
        max_tokens: 100,
        temperature: 1.3, // What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.
        // presence_penalty: 2.0,
        // frequency_penalty: 2.0,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
