import { colors } from "./_defaults";

const showBorder = false;

const containerStyle = {
  paddingTop: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "90vh",
  width: "85%",
  border: showBorder ? "3px solid black" : "none",
};

const gridItemStyle = {
  flex: 1,
  padding: "12px",
  margin: "24px 48px",
  border: showBorder ? "1px solid #ccc" : "none",
};

const headingStyle = {
  fontWeight: "bold",
  marginTop: "25%",
  marginBottom: "5%",
  border: showBorder ? "1px solid #ccc" : "none",
  fontSize: "48px",
  color: colors.secondaryText,
};

const descriptionStyle = {
  border: showBorder ? "1px solid #ccc" : "none",
  fontSize: "24px",
  color: colors.secondaryText,
};

const imageContainerStyle = {
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "24px",
};

const imageStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
  boxShadow: "0 0 8px 8px white inset",
};

export {
  containerStyle,
  gridItemStyle,
  headingStyle,
  descriptionStyle,
  imageContainerStyle,
  imageStyle,
};
