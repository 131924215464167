// App.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Navigation from "./components/basics/Navigation";
import HomePage from "./pages/home/HomePage";
import DecisionPage from "./pages/scenarios/DecisionPage";
import PremiumPage from "./pages/scenarios/PremiumPage";
import BasicPage from "./pages/scenarios/BasicPage";
import NotFoundPage from "./pages/NotFoundPage";
import TestDevPage from "./pages/TestDevPage";
import ScrollToTop from "./components/basics/ScrollToTop";

import { createTheme, ThemeProvider } from "@mui/material";
import { backgroundStyle } from "./styles/_defaults";
import { colors } from "./styles/_defaults";
import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#001f3f",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "20px",
          fontSize: "17px",
          fontWeight: "400",
        },
        contained: {
          color: colors.primaryText,
        },
        outlined: {
          color: colors.secondaryText,
        },
        text: {
          color: colors.secondaryText,
        },
      },
    },
  },
  typography: {
    fontFamily:
      '"SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
});

const App = () => {
  return (
    <div style={backgroundStyle}>
      <ThemeProvider theme={theme}>
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/decision/" element={<DecisionPage />} />
          <Route path="/basic" element={<BasicPage />} />
          <Route path="/premium" element={<PremiumPage />} />
          <Route path="/test/" element={<TestDevPage />} />
          <Route path="/signup/" element={<SignupPage />} />
          <Route path="/login/" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ScrollToTop />
      </ThemeProvider>
    </div>
  );
};

export default App;
