import React from "react";
import { Grid, Typography, Button, Slider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PlacesAutocompleteSearchBarBase from "./PlacesAutocompleteSearchBarBase";

export default function LocationInputs({
  userLocation,
  setUserLocation,
  handleFetchLocationClick,
  isLocationLoading,
  radius,
  handleRadiusChange,
  RADIUS_MIN,
  RADIUS_MAX,
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PlacesAutocompleteSearchBarBase setSelected={setUserLocation} />
      </Grid>

      <Grid item xs={9} sx={{ mt: 3 }}>
        <Typography variant="h7">
          <span style={{ fontWeight: "bold" }}>Location:</span> {userLocation}
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ mt: 3, px: 2 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleFetchLocationClick}
          loading={isLocationLoading}
          sx={{
            height: "30px",
            fontSize: "12px",
            width: "100%",
          }}
          style={{ textTransform: "none" }}
        >
          Get Location
        </LoadingButton>
      </Grid>

      <Grid item xs={6} sx={{ mt: 1 }}>
        <Typography variant="h7">Location Radius: {radius} km</Typography>
      </Grid>
      <Grid item xs={6} sx={{ mt: 1 }}>
        <Slider
          value={radius}
          onChange={handleRadiusChange}
          min={RADIUS_MIN}
          max={RADIUS_MAX}
          valueLabelDisplay="auto"
        />
      </Grid>
    </Grid>
  );
}
