import React from "react";
import { Divider } from "@mui/material";

export default function SectionDivider() {
  return (
    <Divider
      variant="middle"
      style={{ marginTop: "18px", marginBottom: "18px" }}
    />
  );
}
