const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10%",
};

const cardStyle = {
  backgroundColor: "#fff",
  borderRadius: "20px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  padding: "24px",
  height: "280px",
  width: "550px",
  flexDirection: "column", // Ensure child elements stack vertically
  position: "relative", // Establish positioning context for absolutely positioned button
};

const gridItemStyle = {
  height: "100%",
  paddingTop: "24px",
};

const decisionTypeListStyle = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
};

const listItemStyle = {
  padding: "10%",
  margin: "4px",
  fontSize: "18px",
  fontWeight: "bold",
  "&:hover": {
    //override the default grey background, fade from blue to white
    background: "linear-gradient(to right, #aed4f3, #d3e7f8, white)",
  },
};

const comingSoonStyle = {
  marginLeft: "15px",
  color: "brown",
  fontSize: "12px",
  fontStyle: "italic",
};

const descriptionStyle = {
  flex: 1,
  fontSize: "18px",
};

export {
  modalStyle,
  cardStyle,
  listItemStyle,
  gridItemStyle,
  comingSoonStyle,
  descriptionStyle,
  decisionTypeListStyle,
};
