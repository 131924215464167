import { React, useMemo } from "react";
import { Button } from "@mui/material";
import { generateResponse } from "../services/openaiService.js";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_SERVICES_API_KEY;

export default function TestDevPage() {
  const handleGenerateResponse = async () => {
    const prompt = "Suggest a restaurant near me.";
    const response = await generateResponse(prompt);
    console.log(response);
    console.log(response["choices"][0]["message"]["content"]);
  };

  const containerStyle = {
    width: "600px",
    height: "400px",
  };

  // toronto
  const center = useMemo(() => ({ lat: 43.65, lng: -79.38 }), []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  const renderMap = () => {
    if (isLoaded) {
      return (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
          <Marker position={center} />
        </GoogleMap>
      );
    } else {
      return <div>Loading...</div>;
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateResponse}
      >
        GPT
      </Button>
      {renderMap()}
    </div>
  );
}
