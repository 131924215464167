import StarRatings from "react-star-ratings";
import { Typography, Box } from "@mui/material";

const RatingsBar = ({ stars, numOfReview, priceLevel }) => {
  const formattedNumOfReview = numOfReview.toLocaleString();

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" component="div" style={{ marginRight: "6px", color: "grey" }}>
        {stars}
      </Typography>
      <StarRatings
        rating={stars}
        starRatedColor="orange"
        starDimension="16px"
        starSpacing="1px"
        numberOfStars={5}
        // name="rating"
      />
      <Typography variant="body2" component="div" style={{ marginLeft: "6px", color: "grey" }}>
        ({formattedNumOfReview} Reviews)
      </Typography>
      <Typography variant="body2" component="div" style={{ marginLeft: "6px", color: "grey" }}>
        {priceLevel > 0 && <>⋅ {Array(priceLevel).fill("$").join("")}</>}
      </Typography>
    </Box>
  );
};

export default RatingsBar;
