import React from "react";
import { Button } from "@mui/material";
import { Link as AnchorLink } from "react-scroll";

const StartButton = () => {
  return (
    <>
      <Button variant="contained" color="primary" style={{ marginTop: "24px" }}>
        <AnchorLink to="options-section" smooth={true} duration={500}>
          Get Started
        </AnchorLink>
      </Button>
    </>
  );
};

export default StartButton;
