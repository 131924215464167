import React, { useState } from "react";
import { Grid } from "@mui/material";
import { columnStyle } from "../../styles/containerStyles";
import UserPreferencesSection from "../../components/sections/UserPreferencesSection";
import SelectRestaurantSection from "../../components/sections/SelectRestaurantSection";
import SectionDivider from "../../components/sections/SectionDivider";
import DecisionSection from "../../components/sections/DecisionSection";

export default function PremiumPage() {
  const [selected, setSelected] = useState(null);
  const [userLocation, setUserLocation] = useState({
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
  });

  const [restaurantDetailsList, setRestaurantDetailsList] = useState([]);

  const [foodPreferences, setFoodPreferences] = useState("");

  return (
    <div>
      <Grid
        container
        sx={{
          marginTop: "5px",
          maxWidth: "700px",
          my: 2,
          mx: "auto",
        }}
      >
        {/* column 1 */}
        <Grid container item md={12}>
          <Grid item style={columnStyle}>
            <SelectRestaurantSection
              selected={selected}
              setSelected={setSelected}
              restaurantDetailsList={restaurantDetailsList}
              setRestaurantDetailsList={setRestaurantDetailsList}
              userLocation={userLocation}
            />
            <SectionDivider />

            <UserPreferencesSection
              userLocation={userLocation}
              setUserLocation={setUserLocation}
              foodPreferences={foodPreferences}
              setFoodPreferences={setFoodPreferences}
            />
            <SectionDivider />

            <DecisionSection
              userPreferences={foodPreferences}
              restaurantDetailList={restaurantDetailsList}
            />
          </Grid>
        </Grid>

        {/* column 2 */}
        <Grid container item xs={12} md={6}>
          <Grid item style={columnStyle}>
            {/* Decision Container*/}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
