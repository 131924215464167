import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useSignUp = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const signup = async (email, password) => {
    setIsLoading(true);
    setError(null);

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out. Please try again."));
      }, 10000); // Set timeout duration (in milliseconds)
    });

    try {
      const response = await Promise.race([
        fetch("/api/user/signup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        }),
        timeoutPromise,
      ]);

      const json = await response.json();

      if (!response.ok) {
        setError(json.error);
      }

      if (response.ok) {
        // save the user to local storage on browser
        localStorage.setItem("user", JSON.stringify(json));

        // update auth context
        dispatch({ type: "LOGIN", payload: json });
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again later.");
    }

    setIsLoading(false);
  };

  return { signup, error, isLoading };
};
