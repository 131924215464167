import { getGeocode } from "use-places-autocomplete";

// Convert latitude and longitude to address
export async function getAddressFromCoordinates(
  latitude,
  longitude,
  returnWholeAddress = false
) {
  let results;

  try {
    results = await getGeocode({
      location: {
        lat: latitude,
        lng: longitude,
      },
    });
  } catch (error) {
    console.error("Error getting address from coordinates:", error);
    throw new Error("Error getting address");
  }

  if (results && results.length > 0) {
    if (returnWholeAddress) {
      const formattedAddress = results[0].formatted_address;
      return formattedAddress;
    } else {
      const addressComponents = results[0].address_components;
      const cityComponent = addressComponents.find(
        (component) =>
          component.types.includes("locality") ||
          component.types.includes("administrative_area_level_2")
      );
      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );

      const city = cityComponent ? cityComponent.long_name : "";
      const state = stateComponent ? stateComponent.long_name : "";
      const country = countryComponent ? countryComponent.long_name : "";
      return { city, state, country };
    }
  } else {
    throw new Error("Error parsing address");
  }
}
