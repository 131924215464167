import React from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import OptionCard from "./OptionCard";

import { sectionContainerStyle, sectionHeadingStyle } from "../../styles/hompageStyles";
import { backgroundStyle2 } from "../../styles/_defaults.js";

const secondaryTextColor = "#5A5A5A";

const SelectionsSection = () => {
  const scenario_options = [
    {
      id: 222,
      option: "Basic",
      url: "basic",
      mode: "live",
      enabled: true,
      description: " Tailored recommendations for an exquisite dining experience",
      bullets: ["select a location", "share your taste preferences", ,],
      finalStep: "let our engine find your ideal restaurant!",
      tags: ["AI Powered", "personalize", "most popular"],
    },
    {
      id: 333,
      option: "Premium",
      url: "premium",
      enabled: true,
      mode: "beta",
      description: "Embark on a deluxe dining adventure with comprehensive recommendations",
      bullets: [
        "input a few restaurant choices",
        "fetch latest info of selections",
        "enter your unique preferences",
      ],
      finalStep: "let our system decide the perfect place from your list!",
      tags: ["AI Powered", "consistency", "personalize", "smart decision"],
    },
  ];

  return (
    <Box id="options-section" style={backgroundStyle2}>
      <Container maxWidth={false} style={sectionContainerStyle}>
        <Grid container sx={{ mx: "auto", maxWidth: "1000px" }}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" sx={{ ...sectionHeadingStyle, mb: 2 }}>
              Selections
            </Typography>
            <Typography
              variant="h6"
              align="center"
              sx={{
                mb: "10%",
                fontSize: "17px",
                fontWeight: "600",
                color: secondaryTextColor,
              }}
            >
              Get started with a BiteQuest method that works for you.
            </Typography>

            <Grid container sx={{ margin: "auto" }}>
              {scenario_options.map(
                (scenario) =>
                  scenario.enabled && (
                    <Grid item xs={12} md={6} key={scenario.id} sx={{ my: 1 }}>
                      <OptionCard scenario={scenario} />
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SelectionsSection;
