import React, { useState } from "react";
import { Typography, Grid, Paper, List, ListItem, Link, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ClearSharp as DeleteIcon } from "@mui/icons-material";
import PlacesAutocompleteSearchBar from "../common/PlacesAutocompleteSearchBar";
import { innerContainerStyle, innerItemStyle } from "../../styles/containerStyles";
import RestaurantCard from "../common/restaurantCard/RestaurantCard";

const SelectRestaurantSection = ({
  selected,
  setSelected,
  restaurantDetailsList,
  setRestaurantDetailsList,
  userLocation,
}) => {
  const handleDeleteRestaurant = (index) => {
    const updatedList = [...restaurantDetailsList];
    updatedList.splice(index, 1);
    setRestaurantDetailsList(updatedList);
  };

  return (
    <Grid container item xs={12} sx={{ mt: 5 }}>
      <Grid item xs={12} style={innerItemStyle}>
        <Typography variant="h5">Search for a restaurant</Typography>
      </Grid>
      <Grid item xs={12} style={innerItemStyle}>
        <PlacesAutocompleteSearchBar
          setSelected={setSelected}
          restaurantDetailsList={restaurantDetailsList}
          setRestaurantDetailsList={setRestaurantDetailsList}
          userLocation={userLocation}
        />
      </Grid>

      <div style={{ margin: "20px" }}></div>
      <Grid item xs={12} style={innerItemStyle}>
        <Typography variant="h5">Selected Restaurants</Typography>

        <List sx={{ maxHeight: 400, overflowY: "scroll" }}>
          {restaurantDetailsList.map((restaurantDetail, index) => (
            <ListItem key={restaurantDetail.placeId}>
              <RestaurantCard
                restaurantDetail={restaurantDetail}
                handleDeleteRestaurant={() => handleDeleteRestaurant(index)}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default SelectRestaurantSection;
