import React, { useState } from "react";
import { Grid, Container, Typography, Snackbar, Alert } from "@mui/material";
import { getUserLocationCoordinates } from "../../services/customHooks/useBrowserGeolocationService.js";
import { getAddressFromCoordinates } from "../../services/geocodingService.js";
import UserPreferences from "../../components/common/UserPreferences.js";
import LocationInputs from "../../components/common/LocationInputs.js";
import DecisionSection from "../../components/common/DecisionSection.js";
import { InvalidInputError } from "../../errors/customErrors.js";
import { getGPTChatCompletion } from "../../services/openaiService.js";

const RADIUS_MIN = 1;
const RADIUS_MAX = 20;
const RADIUS_INIT = 5;
const RADIUS_UNIT = "km";

export default function BasicPage() {
  const [userLocation, setUserLocation] = useState(null);
  const [preferenceText, setPreferenceText] = useState("");
  const [decision, setDecision] = useState(null);

  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isDecisionLoading, setIsDecisionLoading] = useState(false);

  const [radius, setRadius] = useState(RADIUS_INIT);
  const [alertMessage, setAlertMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleRadiusChange = (_event, newValue) => {
    setRadius(newValue);
  };

  const handleDecide = async () => {
    setDecision(null);
    setIsDecisionLoading(true);

    try {
      // Check if we have a location and some user preferences text
      if (userLocation === null || preferenceText.length < 1) {
        throw new InvalidInputError("Invalid input detected.");
      }

      const messages = buildMessage(userLocation, radius, preferenceText);
      const response = await getGPTChatCompletion(messages);

      // Extract and set the generated response
      setDecision(response.choices[0].message.content);
    } catch (error) {
      if (error instanceof InvalidInputError) {
        console.error("Invalid input error:", error);
        setAlertMessage("Please choose a location and provide valid user preferences");
      } else {
        console.error("Unexpected error occurred calling openai API:", error);
        setAlertMessage("Unexpected error occurred");
      }
      setShowAlert(true);
    } finally {
      setIsDecisionLoading(false);
    }
  };

  const handleFetchLocationClick = async () => {
    setIsLocationLoading(true);
    setUserLocation(null);
    try {
      const { latitude, longitude } = await getUserLocationCoordinates();

      // Get the whole address from coordinates
      const wholeAddress = await getAddressFromCoordinates(latitude, longitude, true);

      // Set user location with coordinates and address information
      setUserLocation(wholeAddress);
    } catch (error) {
      console.error("Error getting location:", error);
      setAlertMessage(
        "Error getting location. Please try again later or enter location mannually."
      );
      setShowAlert(true);
    } finally {
      setIsLocationLoading(false);
    }
  };

  return (
    <div>
      <Grid container>
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            my: { xs: 6 },
            p: { xs: 3 },
          }}
        >
          <Typography variant="h5" align="center" sx={{ mt: 3 }}>
            Basic Scenario
          </Typography>

          <LocationInputs
            userLocation={userLocation}
            setUserLocation={setUserLocation}
            handleFetchLocationClick={handleFetchLocationClick}
            isLocationLoading={isLocationLoading}
            radius={radius}
            handleRadiusChange={handleRadiusChange}
            RADIUS_MIN={RADIUS_MIN}
            RADIUS_MAX={RADIUS_MAX}
          />

          <UserPreferences
            preferenceText={preferenceText}
            setPreferenceText={setPreferenceText}
          ></UserPreferences>

          <DecisionSection
            handleDecide={handleDecide}
            isDecisionLoading={isDecisionLoading}
            decision={decision}
          ></DecisionSection>

          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setShowAlert(false)}
          >
            <Alert severity="error" onClose={() => setShowAlert(false)}>
              {alertMessage}
            </Alert>
          </Snackbar>
        </Container>
      </Grid>
    </div>
  );
}

/* Model optimization

Issue 1: A single restaurant is returned after calling the API multiple times

Ex message:
  location = "Toronto" 
  radius = 10km
  user preference = "something fancy, for a special occasion"
Canoe restaurant is returned 90% of the time, only with slighty different descriptions

Tried solutions
- adjust input params: tempurature, top_p, n, 
- adjust the system prompt. (this is probably best before gpt4 ready)

Ideas:
- do 1 thing at a time, find a restaurant first, then description
- use gpt 4, when available 

Issue 2: Restaurant outside of radius range


Future:
- add "return in json format" to make easier to parse
- also provide the json object skeleton 
*/

// "You're an assistant tasked with recommending a restaurant based on location, radius, and user preferences. Search your knowledge base and find 10 suitable restaurant first, list their names. Then randomly pick 1 restaurant and ONLY provide a 2-3 sentence description of the selected restaurant",

const buildMessage = (locationString, radius, preferenceText) => {
  const messages = [
    {
      role: "system",
      content: "Be like an engineer, provide answers, do not repeat input ",
    },
    {
      role: "user",
      content: `Use the parameters below to find a suitable restaurant. Tell me the name of the restaurant, and a short 2 sentence description.
          user location: ${locationString}, 
          radius: ${radius} ${RADIUS_UNIT},
          user preference: ${preferenceText}`,
    },
  ];

  return messages;
};
