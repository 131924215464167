import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

const FOOD_PLACE_TYPES = ["restaurant", "cafe", "bar", "bakery", "food"]; // https://developers.google.com/maps/documentation/places/web-service/supported_types

const SEARCH_RADIUS_METERS = 5000; //5km

const fetchPlaceDetails = async (placeId) => {
  // call place details API using placeID
  const parameter = {
    placeId: placeId,
    fields: [
      "name",
      "rating",
      "user_ratings_total",
      "current_opening_hours",
      "formatted_address",
      "formatted_phone_number",
      "website",
      "price_level",
      "photos", // get photo reference, pass to place photo API
    ], // specify at least one field to avoid large response charges
  };

  try {
    const details = await getDetails(parameter);
    return details;
  } catch (error) {
    console.log("Error: ", error);
    return null;
  }
};

const calculateSearchBounds = (latitude, longitude) => {
  return {
    north: latitude + SEARCH_RADIUS_METERS / 111000, // Approx. 1 degree of lat is ~111000m
    south: latitude - SEARCH_RADIUS_METERS / 111000,
    east: longitude + SEARCH_RADIUS_METERS / (111000 * Math.cos(latitude * (Math.PI / 180))),
    west: longitude - SEARCH_RADIUS_METERS / (111000 * Math.cos(latitude * (Math.PI / 180))),
  };
};

const PlacesAutocompleteSearchBar = ({
  setSelected,
  restaurantDetailsList,
  setRestaurantDetailsList,
  userLocation,
}) => {
  let restaurantRequestOptions = {
    types: FOOD_PLACE_TYPES, // search only food places
  };
  if (userLocation && userLocation.latitude && userLocation.longitude) {
    const searchBounds = calculateSearchBounds(userLocation.latitude, userLocation.longitude);
    restaurantRequestOptions = {
      ...restaurantRequestOptions,
      bounds: searchBounds,
      strictBounds: false, // Set to true to limit results strictly within the bounds
    };
  }

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({ requestOptions: restaurantRequestOptions });

  const handleSelect = async (address, placeId) => {
    if (address) {
      setValue(address, false);
      clearSuggestions();
      setSelected({ address, placeId });
    }
    const details = await fetchPlaceDetails(placeId);
    if (details) {
      // add new details to the restaurant details list
      details.placeId = placeId;
      setRestaurantDetailsList([...restaurantDetailsList, details]);
    }
  };

  useEffect(() => {
    console.log("=========Updated details list:", restaurantDetailsList);
  }, [restaurantDetailsList]);

  return (
    <Autocomplete
      freeSolo
      inputValue={value}
      onInputChange={(_, newInputValue) => setValue(newInputValue)}
      options={status === "OK" ? data.map((item) => item.description) : []}
      filterOptions={(x) => x} // override buil-in filtering, show all options in drop down
      loading={!ready}
      renderInput={(params) => (
        <TextField {...params} label="Find your restaruants" variant="outlined" fullWidth />
      )}
      onChange={(event, newValue) => {
        const selectedSuggestion = data.find((item) => item.description === newValue);
        if (selectedSuggestion) {
          handleSelect(newValue, selectedSuggestion.place_id);
        }
      }}
    />
  );
};

export default PlacesAutocompleteSearchBar;
