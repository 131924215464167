import React, { useState } from "react";
import { Card, CardContent, Typography, TextField, Alert, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSignUp } from "../services/customHooks/useSignUp";
import { Link as RouterLink } from "react-router-dom";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isLoading } = useSignUp();

  const handleSignup = async (e) => {
    e.preventDefault();

    await signup(email, password);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          boxShadow: 3,
          maxWidth: 600,
          mt: 20,
          mx: 3,
        }}
      >
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h5" align="center">
            SIGN UP
          </Typography>

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mt: 2 }}
          />
          <LoadingButton
            variant="contained"
            loading={isLoading}
            color="primary"
            onClick={handleSignup}
            sx={{ mt: 2, width: "100%", borderRadius: "3px" }}
            disabled={isLoading}
          >
            SIGN UP
          </LoadingButton>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Already have an account?{" "}
            <Link component={RouterLink} to="/login" underline="hover">
              Login here
            </Link>
          </Typography>

          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SignupPage;
