import { Typography } from "@mui/material";
import { getRestaurantHourHelper } from "../../../services/getRestaurantHourHelper";
import { colors } from "../../../styles/_defaults";

const HoursBar = ({ currentHours, handleOpenHourModal }) => {
  return (
    <Typography variant="body2" color="text.secondary">
      {currentHours.open_now ? (
        <>
          <span style={{ color: "green" }}>Open</span> ⋅{" "}
          {getRestaurantHourHelper(currentHours.periods, true)}
        </>
      ) : (
        <>
          <span style={{ color: "red" }}>Closed</span> ⋅{" "}
          {getRestaurantHourHelper(currentHours.periods, false)}
        </>
      )}
      {" "}⋅{" "}
      <span
        style={{
          color: colors.primary,
          cursor: "pointer",
        }}
        onClick={handleOpenHourModal}
      >
        More Hours
      </span>
    </Typography>
  );
};

export default HoursBar;
